<template>
  <div class="sub-header-thin-div">
    <div :class="isMobile ? 'm-2' : 'w-75 mx-auto my-25 d-flex flex-column'" v-if="eventInfo">
      <div data-app class="m-5 p-5 box-white">
        <div class="d-flex flex-column center">
          <span :class="`${isMobile ? 'mt-1 font-20' : 'mt-5 font-25'} font-bold color-drink`">{{ $t(isUpdate ? 'edit_drinks_menu' : 'add_drinks_menu') }}</span>
          <img class="mt-5" :src="assets.drink" :width="isMobile ? 150 : 200" />
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('event_name') }}</span>
          <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="eventInfo.name" disabled />
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`" v-if="drinkList.length === 0 || (eventInfo.tagList && eventInfo.tagList.length > 0)">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('tag') }}</span>
          <div :class="`d-flex justify-content-between ${isMobile ? 'mt-1' : 'w-75'}`">
            <b-form-select v-model="selectedTag">
              <b-form-select-option v-for="tag in eventInfo.tagList" :value="tag" :key="tag">{{ tag }}</b-form-select-option>
            </b-form-select>
            <v-btn class="ml-2 button-drink" @click="visibleSetTagDialog = true">
              <img :src="assets.plus" :width="20" />
            </v-btn>
          </div>
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('category') }}</span>
          <div :class="isMobile ? 'mt-1' : 'w-75'">
            <div class="row">
              <div :class="isMobile && index === categories.length - 1 ? 'col-12' : 'col-xl-4 col-6'" v-for="(item, index) in categories" :key="(item, index)">
                <div :id="`divCategory_${index}`" :class="`my-1 category-${item === category ? 'selected' : 'normal'} vertical-center`" @click="category = item; clearQuantityMap();">
                  <img :src="getDrinkIcon(item)" :height="20" />
                  <span class="ml-5 font-12">{{ item }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`" v-if="isLongDrinks()">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">Shot</span>
          <div :class="`box-white d-flex justify-content-between vertical-center pointer ${isMobile ? 'mt-1' : 'w-75'}`" @click="openDrinkDialog(true)">
            <img :src="getDrinkIcon(parentInfo1.category)" :height="20" v-if="parentInfo1" />
            <span class="ml-5 mr-auto font-12">{{ parentInfo1 ? parentInfo1.name : '' }}</span>
            <img :src="assets.arrow_down" :height="15" />
          </div>
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`" v-if="isLongDrinks()">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('non_alcohol') }}</span>
          <div :class="`box-white d-flex justify-content-between vertical-center pointer ${isMobile ? 'mt-1' : 'w-75'}`" @click="openDrinkDialog(false)">
            <img :src="getDrinkIcon(parentInfo2.category)" :height="20" v-if="parentInfo2" />
            <span class="ml-5 mr-auto font-12">{{ parentInfo2 ? parentInfo2.name : '' }}</span>
            <img :src="assets.arrow_down" :height="15" />
          </div>
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`">{{ $t('drink_name') }}</span>
          <input :class="`input-normal ${isMobile ? 'mt-1' : 'w-75'}`" v-model="drinkName" />
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span v-if="!isMobile">&nbsp;</span>
          <span :class="isMobile ? '' : 'mt-3 w-75'">{{ $t('alert_assign_inventory_to_employees') }}</span>
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span v-if="!isMobile">&nbsp;</span>
          <div :class="`${isMobile ? 'ml-3' : 'w-75'} d-flex`">
            <input type="checkbox" id="checkbox" v-model="visibleMyTicket" @change="handleVisibleChange($event)" />
            <label class="ml-2 mb-0" for="checkbox">{{ $t('show_or_hide_organizer') }}</label>
          </div>
        </div>
        <div class="my-5 justify-content-between vertical-center" v-for="(item, index) in quantityKeyList" :key="'employeeInfo' + index">
          <span v-if="index !== 0 || (index === 0 && visibleMyTicket)">&nbsp;</span>
          <div :class="`${isMobile ? 'w-100' : 'w-75'} d-flex justify-content-between vertical-center`" v-if="index !== 0 || (index === 0 && visibleMyTicket)">
            <span class="font-label">{{ item.name }}</span>
            <span class="ml-auto mr-2 font-12" v-if="drinkName">{{ `${soldQuantityMap[item.key] || 0} / ` }}</span>
            <input class="w-25 form-control" v-model="totalQuantityMap[item.key]" maxlength="5" @input="onQuantityInput(item.key, $event.target.value)" :disabled="isLongDrinks()" />
          </div>
        </div>
        <div :class="`mt-5 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <span :class="`font-label ${isMobile ? 'ml-3' : ''}`" v-if="!isMobile">{{ $t('drink') }}</span>
          <div :class="`d-flex ${isMobile ? 'w-100' : 'w-75'}`">
            <div class="d-flex flex-column w-50">
              <span class="font-label ml-2">{{ $t('price') }}</span>
              <div class="mt-1 input-group">
                <input class="form-control" v-model="drinkPrice" maxlength="8" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\.\d{2}).+/g, '$1');" :disabled="isLongDrinks()" />
                <div class="input-group-append">
                  <span class="input-group-text">€</span>
                </div>
              </div>
            </div>
            <div :class="`d-flex flex-column ${isMobile ? 'ml-1 w-25' : 'ml-5'}`">
              <span class="font-label ml-2">{{ $t('amount') }}</span>
              <input class="mt-1 form-control" v-model="totalQuantity" disabled />
            </div>
            <v-btn class="ml-auto mt-5 button-drink" @click="saveDrink()">
              <img :src="drinkId ? assets.check : assets.plus" :width="20" />
            </v-btn>
          </div>
        </div>
        <div :class="`mt-6 d-flex ${isMobile ? 'flex-column' : 'justify-content-between vertical-center'}`">
          <div :class="`${isMobile ? 'w-100' : 'w-50'} d-flex justify-content-between vertical-center mx-auto`">
            <span :class="`font-12 font-bold ${isMobile ? 'ml-3' : ''}`">{{ $t('select_drink_category') }}:</span>
            <b-form-select v-model="selectedCategory" class="mb-3 w-50">
              <b-form-select-option v-for="category in [$t('all'), ...categories]" :value="category" :key="category">{{ category }}</b-form-select-option>
            </b-form-select>
          </div>
        </div>
        <div class="mx-auto my-5 w-75 div-divider-h" v-if="drinkList.length > 0" />
        <div class="my-5 justify-content-between vertical-center" v-for="(item, index) in getFilteredDrinkList()" :key="(item, index)">
          <img :src="getDrinkIcon(item.category)" :height="40" />
          <div class="mx-5 d-flex flex-column">
            <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ item.name }}</span>
            <div class="mt-2 d-flex justify-content-between vertical-center">
              <span :class="isMobile ? 'font-12' : 'font-15'">{{ getPriceFormat(item.price) }}</span>
              <span :class="`ml-auto ${isMobile ? 'font-12' : 'font-15'}`">{{ `${getDecimalFormat(item.soldQuantity)} / ${getDecimalFormat(item.totalQuantity)}` }}</span>
            </div>
            <div class="mx-auto mt-5 w-75 div-divider-h" />
          </div>
          <v-btn icon small @click="editDrink(item)" v-if="item.drinkId">
            <i :class="`ml-1 fa fa-edit color-drink ${isMobile ? 'font-15' : 'font-20'}`"></i>
          </v-btn>
          <v-btn icon small @click="deleteDrinkInfoTask(index, item.drinkId)">
            <i :class="`ml-1 flaticon-delete-1 color-red ${isMobile ? 'font-15' : 'font-20'}`"></i>
          </v-btn>
        </div>
        <div class="my-20 d-flex justify-content-center">
          <v-btn class="button-drink" @click="setDrinkInfoTask()">
            <span :class="`${isMobile ? 'mx-20' : 'mx-40'}`">{{ $t(`${isUpdate ? 'save' : 'add'}`) }}</span>
          </v-btn>
        </div>
      </div>
      <v-dialog v-model="visibleDrinkDialog" scrollable width="400px">
        <v-card>
          <v-card-title class="justify-content-between">
            <span class="font-bold font-18">{{ $t(isShot ? 'select_shot' : 'select_non_alcohol') }}</span>
            <v-btn class="m-1" icon small @click="visibleDrinkDialog = false">
              <i class="flaticon-cancel font-bold font-25 color-black"></i>
            </v-btn>
          </v-card-title>
          <v-card-text class="mb-10">
            <div class="my-5 box-white justify-content-between vertical-center pointer" v-for="(item, index) in (isShot ? shotList : nonAlcoholList).filter(element => element.tag === selectedTag)" :key="(item, index)" @click="selectParent(item)">
              <img :src="getDrinkIcon(item.category)" :height="40" />
              <div class="mx-5 w-100 d-flex flex-column">
                <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ item.name }}</span>
                <div class="mt-2 d-flex justify-content-between vertical-center">
                  <span :class="isMobile ? 'font-12' : 'font-15'">{{ getPriceFormat(item.price) }}</span>
                  <span :class="`ml-auto ${isMobile ? 'font-12' : 'font-15'}`">{{ `${getDecimalFormat(item.soldQuantity)} / ${getDecimalFormat(item.totalQuantity)}` }}</span>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="visibleSetTagDialog" width="400px">
        <v-card>
          <v-card-title class="justify-content-between">
            <span class="font-bold font-18">{{ $t('set_new_tag') }}</span>
            <v-btn class="m-1" icon small @click="visibleSetTagDialog = false;">
              <i class="flaticon-cancel font-bold font-25 color-black"></i>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="m-5 box-white d-flex flex-column">
              <span class="mt-5 font-12">{{ $t('alert_set_new_tag_instruction') }}</span>
              <span class="mt-10 font-label">{{ $t('tag') }}</span>
              <input class="mt-2 input-normal" v-model="newTag" />
              <div class="my-5 d-flex justify-content-end">
                <v-btn class="ml-auto button-drink" @click="setDrinkEventTagTask()">
                  <span class="mx-10">{{ $t('ok') }}</span>
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<style scoped>
  .category-normal {
    padding: 10px 15px !important;
    border-radius: 10px !important;
    border: 2px solid #E5E5E5 !important;
    background-color: white !important;
    cursor: pointer !important;
    box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
    -moz-box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
    -webkit-box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
  }
  .category-selected {
    padding: 10px 15px !important;
    border-radius: 10px !important;
    border: 2px solid #A10559 !important;
    background-color: white !important;
    box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
    -moz-box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
    -webkit-box-shadow: 4px 8px 20px rgba(34,90,89,0.2) !important;
  }
</style>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions, firestore } from '../../../main';
import { onSnapshot, query, collection, where } from 'firebase/firestore';
import { toInt, getRoundValue, getDecimalFormat, getPriceFormat, showLoading, showFunctionError } from '../../../functions';

import drink from '@/assets/images/drink.png';
import arrow_down from '@/assets/images/arrow_down.png';
import plus from '@/assets/images/plus.png';
import check from '@/assets/images/check.png';

import drink_longdrinks from '@/assets/images/drink/Longdrinks.png';
import drink_shots from '@/assets/images/drink/Shots.png';
import drink_cocktail from '@/assets/images/drink/Cocktail.png';
import drink_spirituosen from '@/assets/images/drink/Spirituosen.png';
import drink_champagner from '@/assets/images/drink/Champagner.png';
import drink_wein from '@/assets/images/drink/Wein.png';
import drink_sonstiges from '@/assets/images/drink/Sonstiges.png';
import drink_bier from '@/assets/images/drink/Bier.png';
import drink_alkoholfreie from '@/assets/images/drink/Alkoholfreie Getränke.png';

export default {
  name: 'EditDrink',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    myInfo() {
      return this.$store.state.myInfo;
    },
    eventList() {
      return this.$store.state.eventList;
    },
    quantityKeyList() {
      const list = [];
      list.push({
        key: this.$store.state.myId,
        name: this.myInfo.userName
      });
      const employeeList = this.$store.state.employeeList.filter(element => element.inUse === true && element.role === 2 && element.eventId === this.$route.params.eventId);
      employeeList.sort((a, b) => (a.name > b.name) ? 1 : -1);
      employeeList.forEach(employeeInfo => {
        list.push({
          key: employeeInfo.name,
          name: employeeInfo.name
        });
      });
      return list;
    }
  },
  watch: {
    eventList() {
      this.checkEvent();
    }
  },
  data() {
    return {
      categories: ['Longdrinks', 'Shots', 'Bier', 'Cocktail', 'Wein', 'Spirituosen', 'Champagner', 'Sonstiges', 'Alkoholfreie Getränke'],
      assets: {
        drink,
        arrow_down,
        plus,
        check
      },
      isUpdate: false,
      eventInfo: null,
      selectedTag: '',
      drinkId: '',
      category: '',
      drinkName: '',
      drinkPrice: '',
      totalQuantity: 0,
      soldQuantity: 0,
      drinkList: [],
      shotList: [],
      nonAlcoholList: [],
      visibleDrinkDialog: false,
      isShot: false,
      visibleSetTagDialog: false,
      newTag: '',
      soldQuantityMap: {},
      totalQuantityMap: {},
      parentInfo1: null,
      parentInfo2: null,
      snapDrinkList: null,
      visibleMyTicket: false,
      selectedCategory: this.$t('all'),
    };
  },
  mounted() {
    if (this.checkPermission()) {
      this.getDrinkListTask();
      this.checkEvent();
    }
  },
  destroyed() {
    if (this.snapDrinkList) {
      this.snapDrinkList();
    }
  },
  methods: {
    getDecimalFormat,
    getPriceFormat,
    checkPermission() {
      if (this.$route.params.eventId) {
        if (this.myInfo) {
          if (this.myInfo.userType === this.USER_ORGANIZER) {
            return true;
          } else {
            this.$toast.error('No permission!!');
            this.$router.push('/');
            return false;
          }
        } else {
          this.$toast.info(this.$t('alert_sign_in'));
          this.$router.push('/');
          return false;
        }
      } else {
        this.$router.push('/');
      }
    },
    checkEvent() {
      if (this.eventList) {
        const eventInfo = this.eventList.find(element => element.eventId === this.$route.params.eventId);
        if (eventInfo) {
          if (eventInfo.userId !== this.$store.state.myId) {
            this.$toast.error('This is not your event!!');
            this.$router.push('/my_events/1');
          } else {
            if (eventInfo.tag) {
              this.selectedTag = eventInfo.tag;
            }
            if (eventInfo.tagList) {
              eventInfo.tagList.reverse();
            }
            this.eventInfo = eventInfo;
          }
        } else {
          this.$toast.error('Invalid event!');
          this.$router.push('/my_events/1');
        }
      }
    },
    checkTag() {
      if (this.eventInfo) {
        if (this.eventInfo.tag) {
          return this.eventInfo.tag === this.selectedTag;
        }
        return true;
      }
      return false;
    },
    getDrinkIcon(name) {
      switch (name) {
        case 'Longdrinks':
          return drink_longdrinks;
        case 'Shots':
          return drink_shots;
        case 'Cocktail':
          return drink_cocktail;
        case 'Spirituosen':
          return drink_spirituosen;
        case 'Champagner':
          return drink_champagner;
        case 'Wein':
          return drink_wein;
        case 'Sonstiges':
          return drink_sonstiges;
        case 'Bier':
          return drink_bier;
        case 'Alkoholfreie Getränke':
          return drink_alkoholfreie;
        default:
          return drink;
      }
    },
    onQuantityInput(key, value) {
      this.totalQuantityMap[key] = value.replace(/[^0-9]/g,'');
      var totalQuantity = 0;
      this.quantityKeyList.forEach(info => {
        totalQuantity += toInt(this.totalQuantityMap[info.key] || 0);
      });
      this.totalQuantity = totalQuantity;
    },
    clearQuantityMap() {
      this.quantityKeyList.forEach(info => {
        this.totalQuantityMap[info.key] = '';
      });
    },
    isLongDrinks() {
      return this.category === this.categories[0];
    },
    openDrinkDialog(isShot) {
      this.isShot = isShot;
      this.visibleDrinkDialog = true;
    },
    selectParent(drinkInfo) {
      if (this.isShot) {
        this.parentInfo1 = drinkInfo;
      } else {
        this.parentInfo2 = drinkInfo;
      }
      if (this.parentInfo1 && this.parentInfo2) {
        this.drinkPrice = getRoundValue((this.parentInfo1.price * 2 + this.parentInfo2.price) / 100);
        var totalQuantity = 0;
        this.quantityKeyList.forEach(info => {
          const quantity = Math.min(toInt(this.parentInfo1.totalQuantityMap[info.key] || 0), toInt(this.parentInfo2.totalQuantityMap[info.key] || 0));
          this.totalQuantityMap[info.key] = quantity;
          totalQuantity += quantity;
        });
        this.totalQuantity = totalQuantity;
      } else {
        this.drinkPrice = '';
        this.totalQuantity = '';
      }
      this.visibleDrinkDialog = false;
    },
    saveDrink() {
      if (!this.checkPermission()) {
        return;
      }
      if (!this.checkTag()) {
        this.$toast.info(this.$t('alert_tag_is_expired'));
        return;
      }
      var totalQuantity = 0;
      const totalQuantityMap = {};
      this.quantityKeyList.forEach(info => {
        const quantity = toInt(this.totalQuantityMap[info.key] || 0);
        totalQuantityMap[info.key] = quantity;
        totalQuantity += quantity;
      });
      const drinkInfo = {
        drinkId: this.drinkId,
        category: this.category,
        name: this.drinkName,
        price: getRoundValue(this.drinkPrice) * 100,
        tag: this.selectedTag,
        totalQuantity: totalQuantity,
        soldQuantity: this.soldQuantity,
        totalQuantityMap: totalQuantityMap
      }
      if (!drinkInfo.category) {
        this.$toast.error(this.$t('alert_select_category'));
        return;
      }
      if (this.isLongDrinks()) {
        if (!this.parentInfo1) {
          this.$toast.error(this.$t('alert_select_shot'));
          return;
        }
        if (!this.parentInfo2) {
          this.$toast.error(this.$t('alert_select_non_alcohol'));
          return;
        }
        drinkInfo['parentIDs'] = [this.parentInfo1.drinkId, this.parentInfo2.drinkId];
      }
      if (!drinkInfo.name) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('drink_name')]));
        return;
      }
      if (!drinkInfo.totalQuantity) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('amount')]));
        return;
      }
      if (!drinkInfo.price) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('price')]));
        return;
      }
      if (drinkInfo.drinkId) {
        const index = this.drinkList.findIndex(element => element.drinkId === drinkInfo.drinkId);
        if (index !== -1) {
          this.drinkList[index] = drinkInfo;
        }
      } else {
        this.drinkList.unshift(drinkInfo);
      }
      this.drinkList.filter(element => element.parentIDs && element.parentIDs.length === 2 && element.parentIDs.includes(this.drinkId)).forEach(childInfo => {
				const parentInfo1 = this.drinkList.find(element => element.drinkId === childInfo.parentIDs[0]);
				const parentInfo2 = this.drinkList.find(element => element.drinkId === childInfo.parentIDs[1]);
				if (parentInfo1 != null && parentInfo2 != null) {
					childInfo.price = parentInfo1.price * 2 + parentInfo2.price;
          childInfo.totalQuantity = 0;
          childInfo.totalQuantityMap = {};
          this.quantityKeyList.forEach(info => {
            const quantity = Math.min(toInt(parentInfo1.totalQuantityMap[info.key] || 0), toInt(parentInfo2.totalQuantityMap[info.key] || 0));
            childInfo.totalQuantityMap[info.key] = quantity;
            childInfo.totalQuantity += quantity;
          });
				}
			});
      this.drinkId = '';
      this.drinkName = '';
      this.drinkPrice = '';
      this.totalQuantity = '';
      this.soldQuantity = 0;
      this.clearQuantityMap();
    },
    editDrink(drinkInfo) {
      if (!this.checkTag()) {
        this.$toast.info(this.$t('alert_tag_is_expired'));
        return;
      }
      this.drinkId = drinkInfo.drinkId;
      this.category = drinkInfo.category;
      this.drinkName = drinkInfo.name,
      this.drinkPrice = getRoundValue(drinkInfo.price / 100);
      this.totalQuantity = drinkInfo.totalQuantity;
      this.soldQuantity = drinkInfo.soldQuantity;
      this.totalQuantityMap = drinkInfo.totalQuantityMap || {};
      this.soldQuantityMap = drinkInfo.soldQuantityMap || {};
      if (drinkInfo.parentIDs && drinkInfo.parentIDs.length === 2) {
        this.parentInfo1 = this.shotList.find(element => element.drinkId === drinkInfo.parentIDs[0]);
        this.parentInfo2 = this.nonAlcoholList.find(element => element.drinkId === drinkInfo.parentIDs[1]);
      }
      document.getElementById(`divCategory_${this.isMobile ? 6 : 0}`).scrollIntoView();
    },
    getDrinkListTask() {
      if (this.snapDrinkList || !this.$route.params.eventId) {
        return;
      }
      const q = query(collection(firestore, 'drink'), where('eventId', '==', this.$route.params.eventId), where('deletedAt', '==', null));
      this.snapDrinkList = onSnapshot(q, querySnapshot => {
        const drinkList = [];
        querySnapshot.forEach(doc => {
          const info = doc.data();
          if (!info.tag) {
            info['tag'] = '';
          }
          drinkList.push(info);
        });
        this.drinkList = drinkList;
        this.shotList = JSON.parse(JSON.stringify(drinkList.filter(element => element.category === this.categories[1])));
        this.nonAlcoholList = JSON.parse(JSON.stringify(drinkList.filter(element => element.category === this.categories[8])));
        if (drinkList.length > 0) {
          this.isUpdate = true;
        }
      });
    },
    deleteDrinkInfoTask(index, drinkId) {
      if (drinkId) {
        if (!this.checkPermission()) {
          return;
        }
        if (!this.checkTag()) {
          this.$toast.info(this.$t('alert_tag_is_expired'));
          return;
        }
        if (!confirm(this.$t('confirm_delete'))) {
          return;
        }
        const params = {
          userId: this.$store.state.myId,
          isDelete: true,
          drinkId: drinkId
        }
        const loader = showLoading(this, 1, true);
        const func = httpsCallable(functions, 'setDrinkInfo');
        func(JSON.stringify(params)).then(response => {
          loader.hide();
          if (response.data !== this.RESULT_SUCCESS) {
            showFunctionError(this, response.data);
          }
        }).catch(error => {
          loader.hide();
          this.$toast.error(error.code + ', ' + error.message);
        });
      } else {
        this.drinkList.splice(index, 1);
      }
    },
    getFilteredDrinkList() {
      return this.drinkList.filter(element => (this.selectedCategory === this.$t('all') || element.category === this.selectedCategory) && element.tag === this.selectedTag);
    },
    setDrinkInfoTask() {
      if (!this.checkPermission()) {
        return;
      }
      if (!this.checkTag()) {
        this.$toast.info(this.$t('alert_tag_is_expired'));
        return;
      }
      const drinkList = this.drinkList.filter(element => element.tag === this.selectedTag)
      if (drinkList.length === 0) {
        this.$toast.error(this.$t('alert_add_drinks_menu'));
        return;
      }
      const params = {
        userId: this.$store.state.myId,
        eventId: this.$route.params.eventId,
        drinkList: drinkList
      }
      const origList = this.drinkList.filter(element => element.drinkId);
      const loader = showLoading(this, 1, true);
      const func = httpsCallable(functions, 'setDrinkInfo');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          if (origList) {
            this.$toast.success(this.$t('alert_updated_successfully'));
          } else {
            this.$toast.success(this.$t('alert_added_successfully'));
          }
          this.$router.push(`/my_events/1`);
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    handleVisibleChange(event) {
      const value = event.target.checked;
      this.visibleMyTicket = value;
    },
    setDrinkEventTagTask() {
      if (!this.checkPermission()) {
        return;
      }
      if (!this.newTag) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('tag')]));
        return;
      }
      const params = {
        userId: this.$store.state.myId,
        eventId: this.$route.params.eventId,
        newTag: this.newTag
      }
      const loader = showLoading(this, 1, true);
      const func = httpsCallable(functions, 'duplicateDrinkInfo');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.selectedTag = this.newTag;
          this.newTag = '';
          this.visibleSetTagDialog = false;
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>